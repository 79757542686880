
import { defineComponent } from "vue";
import UserProfile from "@/components/users/userProfile/index.vue";
//import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "UserProfileView",
  components: {
    UserProfile
  }
});
