<template>
  <div class="card" id="kt_profile_details_view">
    <div class="card-header cursor-pointer">
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Edit user general</h3>
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button
          :disabled="isLoading"
          @click="saveData"
          class="btn btn-sm btn-success align-self-center"
        >
          <span
            v-if="isLoading"
            class="spinner-border spinner-border-sm"
          ></span>
          Save
        </button>
        <button
          @click="$emit('discard')"
          class="btn btn-sm btn-danger align-self-center "
        >
          Discard
        </button>
      </div>
    </div>
    <div class="card-body p-9">
      <div class="row mb-7">
        <div
          class="col-2 symbol symbol-100px symbol-lg-160px symbol-fixed position-relative me-3"
        >
          <div
            class="image-input image-input-outline"
            data-kt-image-input="true"
            style="background-image: url('/metronic8/demo1/assets/media/svg/avatars/blank.svg')"
          >
            <div
              class="image-input-wrapper w-125px h-125px"
              :style="`background-image: url(${imageSource})`"
            ></div>
            <label
              class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
              data-kt-image-input-action="change"
              data-bs-toggle="tooltip"
              data-kt-initialized="1"
            >
              <i class="bi bi-pencil-fill fs-7"></i>
              <input
                type="file"
                name="avatar"
                accept=".png, .jpg, .jpeg"
                ref="profilePicInput"
                @change="handleImageUpload()"
              />
              <input type="hidden" name="avatar_remove" />
            </label>
            <span
              class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
              data-kt-image-input-action="cancel"
              data-bs-toggle="tooltip"
              data-kt-initialized="1"
            >
              <i class="bi bi-x fs-2"></i>
            </span>
          </div>
        </div>
        <div class="col-4">
          <div class="row align-items-center mb-5">
            <label class="col-4 fw-bold text-muted">First name</label>

            <div class="col-8">
              <InputField
                :placeholder="'Enter first name'"
                v-model="data.first_name"
              ></InputField>
            </div>
          </div>
          <div class="row align-items-center mb-5">
            <label class="col-4 fw-bold text-muted">Middle name</label>

            <div class="col-8">
              <InputField
                :placeholder="'Enter middle name'"
                v-model="data.middle_name"
              ></InputField>
            </div>
          </div>
          <div class="row align-items-center">
            <label class="col-4 fw-bold text-muted">Last name</label>

            <div class="col-8">
              <InputField
                :placeholder="'Enter last name'"
                v-model="data.last_name"
              ></InputField>
            </div>
          </div>
        </div>
        <div class="col-4 offset-1">
          <div class="row align-items-center mb-5">
            <label class="col-3 fw-bold text-muted">Phone</label>

            <div class="col-9">
              <InputField
                :placeholder="'Enter phone number'"
                v-model="data.phone"
              ></InputField>
            </div>
          </div>
          <div
            class="row align-items-center mb-5"
            v-if="$can('user_edit_roles', 'all')"
          >
            <label class="col-3 fw-bold text-muted">Roles</label>
            <div class="col-9">
              <RolesDropdown v-model="data.role"></RolesDropdown>
            </div>
          </div>
          <div
            class="row align-items-center"
            v-if="$can('user_edit_email', 'all')"
          >
            <label class="col-3 fw-bold text-muted">E-mail</label>

            <div class="col-9">
              <InputField
                :placeholder="'Enter e-mail'"
                v-model="data.email"
              ></InputField>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "vue";
import Img from "@/components/general/Img.vue";
import InputField from "@/components/general/InputField.vue";
import RolesDropdown from "@/components/dropdown/RolesDropdown.vue";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "EditUserGeneral",
  components: {
    InputField,
    RolesDropdown
  },
  data() {
    return {
      data: {
        first_name: "",
        middle_name: "",
        last_name: "",
        role: "",
        email: "",
        phone: ""
      },
      imageSource: "",
      file: null,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters("UsersModule", ["getSingleUser"]),
    ...mapGetters({ currentUser: "currentUser" })
  },
  methods: {
    ...mapActions("UsersModule", [
      "update",
      "updateProfilePicture",
      "getUserById"
    ]),
    handleImageUpload() {
      this.file = this.$refs.profilePicInput.files[0];
      this.imageSource = URL.createObjectURL(this.file);
    },
    saveData() {
      this.isLoading = true;
      const payload = {
        ...this.data,
        languages: this.getSingleUser.languages.map(language => language.id),
        primary_branch_id: this.getSingleUser.branches
          .filter(branch => branch.pivot.is_primary == 1)
          .map(branch => branch.id),
        branches: this.getSingleUser.branches
          .filter(branch => branch.pivot.is_primary == 0)
          .map(branch => branch.id)
      };
      this.update({
        id: this.$route.params.id,
        payload: payload
      }).then(() => {
        if (this.file) {
          this.updateProfilePicture({
            file: this.file,
            id: this.getSingleUser.id
          }).then(() => {
            this.getUserById(this.getSingleUser.id);
            this.notifySuccess();
          });
        } else {
          this.getUserById(this.getSingleUser.id);
          this.notifySuccess();
        }
      });
    },
    notifySuccess() {
      Swal.fire({
        text: "The record has been successfully updated !",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn btn-primary"
        }
      }).then(() => {
        this.isLoading = true;
        this.$emit("saved");
      });
    }
  },
  mounted() {
    this.data.first_name = this.getSingleUser.first_name;
    this.data.middle_name = this.getSingleUser.middle_name;
    this.data.last_name = this.getSingleUser.last_name;
    this.data.email = this.getSingleUser.email;
    this.data.phone = this.getSingleUser.phone;
    this.data.role = this.getSingleUser.role.map(role => role.id);
    this.imageSource = this.getSingleUser.photo_url;
  }
});
</script>
