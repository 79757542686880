
import { defineComponent, onMounted, PropType, ref } from "vue";
import { useStore } from "vuex";
import CountUp from "vue-countup-v3";

export interface Totals {
  [currency: string]: number;
}

export interface WorkerUnpaidEarnings {
  totals: Totals[];
  count: number;
}

export default defineComponent({
  name: "unpaid-earnings-widget-admin",
  props: {
    userId: {
      type: Number as PropType<number>,
      default: () => -1
    }
  },
  components: {CountUp},
  setup: function(props) {
    const store = useStore();
    const loading = ref<boolean>(false);
    const earnings = ref<WorkerUnpaidEarnings>({
      totals: [],
      count: 0
    });

    function setLoading(value: boolean): void {
      loading.value = value;
    }
    onMounted(() => {
      if (-1 === props.userId || typeof props.userId !== "number") {
        return;
      }
      setLoading(true);
      store
        .dispatch("UsersModule/fetchUnpaidEarnings", props.userId)
        .then((data: WorkerUnpaidEarnings) => {
          earnings.value = data;
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    });
    return {
      earnings,
      loading
    };
  }
});
