<template>
  <div class="card" v-if="!loading">
    <EditUserGeneral
      v-if="isEditing"
      @discard="isEditing = false"
      @saved="isEditing = false"
    ></EditUserGeneral>
    <div class="card-body pt-9 pb-0" v-else>
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <div class="me-7 mb-4">
          <div
            class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
          >
            <Img :src="getSingleUser.photo_url" alt="image" />
          </div>
        </div>
        <div class="flex-grow-1">
          <div
            class="d-flex justify-content-between align-items-start flex-wrap mb-2"
          >
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center mb-2">
                <a
                  href="#"
                  class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                >
                  {{ getSingleUser.name }}</a
                >
              </div>
              <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 ">
                <span
                  href="#"
                  class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                  ><span class="svg-icon svg-icon-4 me-1"
                    ><svg
                      fill="none"
                      viewBox="0 0 24 24"
                      height="24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        opacity="0.3"
                        d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                        fill="black"
                      ></path>
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                        fill="black"
                      ></path></svg
                  ></span>
                  <span
                    v-for="(entry, index) in getSingleUser.role"
                    :key="entry.id"
                  >
                    <span v-if="index != 0">, </span>{{ entry.title }}
                  </span>
                </span>
              </div>
            </div>

            <div class="d-flex my-4">
              <button
                @click="isEditing = true"
                v-if="
                  canEditProfile ||
                    $can('edit_user_profile_info', 'all') ||
                    currentUser.id == $route.params.id
                "
                class="btn btn-sm btn-light me-2"
                id="editUserButton"
              >
                <span class="svg-icon svg-icon-3 d-none"
                  ><svg
                    fill="none"
                    viewBox="0 0 24 24"
                    height="24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      opacity="0.3"
                      d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z"
                      fill="black"
                    ></path>
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z"
                      fill="black"
                    ></path></svg
                ></span>
                Edit</button
              ><button
                @click="impersonateUser"
                v-if="$can('user_impersonate', 'all')"
                class="btn btn-sm btn-warning me-2"
              >
                Impersonate</button
              ><a
                @click.prevent="handleBackButton"
                class="btn btn-sm btn-primary me-3"
                >Back</a
              >
            </div>
          </div>
          <div class="d-flex flex-wrap flex-stack ms-3">
            <!--begin::Wrapper-->
            <div class="d-flex flex-column flex-grow-1 pe-8">
              <!--begin::Stats-->
              <div class="d-flex flex-wrap">
                <!--begin::Stat-->
                <div
                  class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <span class="svg-icon-3 svg-icon-dark me-2">
                      <inline-svg
                        src="media/icons/duotone/Communication/Mail.svg"
                      />
                    </span>
                    <a
                      class="fs-3 text-dark text-hover-primary"
                      :href="'mailto:' + getSingleUser.email"
                    >
                      {{ getSingleUser.email }}
                    </a>
                  </div>
                  <!--end::Number-->
                </div>
                <!--end::Stat-->

                <!--begin::Stat-->
                <div
                  class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                  v-if="getSingleUser.phone"
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <span class="svg-icon svg-icon-3 svg-icon-dark me-2">
                      <inline-svg
                        src="media/icons/duotone/Interface/Phone.svg"
                      />
                    </span>
                    <a
                      class="fs-3  text-dark text-hover-primary"
                      :href="'tel:' + getSingleUser.phone"
                    >
                      {{ getSingleUser.phone }}
                    </a>
                  </div>
                  <!--end::Number-->
                </div>
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Wrapper-->
            <UnpaidEarningsWidgetAdmin
              :user-id="getSingleUser.id"
              v-if="$can('can_view_unpaid_earnings', 'all')"
            />
          </div>
        </div>
      </div>
      <div class="d-flex overflow-auto h-55px">
        <ul
          class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
        >
          <li class="nav-item">
            <router-link
              to="personal"
              class="nav-link text-active-primary me-6"
              active-class="active"
              v-if="
                (isAtOwnProfile && $can('can_view_own_personal_tab', 'all')) ||
                  $can('edit_user_profile_info', 'all') ||
                  canEditProfile
              "
            >
              Personal
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="settings"
              class="nav-link text-active-primary me-6"
              active-class="active"
              v-if="
                (isAtOwnProfile &&
                  $can('can_view_own_professional_tab', 'all')) ||
                  $can('edit_user_profile_info', 'all') ||
                  canEditProfile
              "
            >
              Professional
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="
              $can('edit_user_profile_info', 'all') ||
                (isAtOwnProfile && $can('can_view_own_payment_tab', 'all'))
            "
          >
            <router-link
              to="payment"
              class="nav-link text-active-primary me-6 text-nowrap"
              active-class="active"
            >
              Payment information
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('note_access', 'all')">
            <router-link
              to="notes"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Notes
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="my-documents"
              class="nav-link text-active-primary me-6 text-nowrap"
              active-class="active"
              v-if="
                $can('document_view_other', 'all') ||
                  (isAtOwnProfile && $can('can_view_own_documents_tab', 'all'))
              "
            >
              My documents
            </router-link>
            <router-link
              to="my-skills"
              class="nav-link text-active-primary me-6 text-nowrap"
              active-class="active"
              v-if="
                $can('skills_view_others', 'all') ||
                  (isAtOwnProfile && $can('can_view_own_skills_tab', 'all'))
              "
            >
              My skills
            </router-link>
            <router-link
              v-if="$can('view_user_activity', 'all')"
              to="user-activities"
              class="nav-link text-active-primary me-6 text-nowrap"
              active-class="active"
            >
              Activity
            </router-link>
            <router-link
              v-if="$can('can_edit_own_company_data', 'all')"
              to="about-company"
              class="nav-link text-active-primary me-6 text-nowrap"
              active-class="active"
            >
              About Company
            </router-link>
            <router-link
              to="invited-users"
              class="nav-link text-active-primary me-6 text-nowrap"
              active-class="active"
              v-if="
                ($can('can_view_own_referred_users_list', 'all') &&
                  isAtOwnProfile) ||
                  $can('can_view_all_referred_users_lists', 'all')
              "
            >
              Invited users
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="user-calendar"
              class="nav-link text-active-primary me-6 text-nowrap"
              active-class="active"
              v-if="$can('can_view_other_users_calendar', 'all')"
            >
              Calendar
            </router-link>
          </li>
          <li class="nav-item" v-if="isAtOwnProfile">
            <router-link
              to="user-security"
              class="nav-link text-active-primary me-6 text-nowrap"
              active-class="active"
            >
              Security
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <LoadingSpinner v-if="loading"></LoadingSpinner>
  <router-view v-if="!loading"></router-view>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Img from "@/components/general/Img.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import EditUserGeneral from "@/components/users/userProfile/EditUserGeneral.vue";
import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import UnpaidEarningsWidgetAdmin from "@/components/users/userProfile/UndpaidApplicationWidgetAdmin.vue";

export default defineComponent({
  name: "ViewUserProfile",
  components: {
    UnpaidEarningsWidgetAdmin,
    Img,
    LoadingSpinner,
    EditUserGeneral
  },
  data() {
    return {
      isEditing: false
    };
  },
  mounted() {
    this.getUserById(this.$route.params.id);
  },
  methods: {
    ...mapActions("UsersModule", ["getUserById"]),
    impersonateUser() {
      ApiService.post("api/v2/auth/impersonate/" + this.getSingleUser.id)
        .then(({ data }) => {
          this.$store.commit(Mutations.SET_AUTH, data);
        })
        .then(() => {
          location.reload(true);
        });
    },
    handleBackButton() {
      if (this.$route.query.referer == "freelancers") {
        this.$router.push(
          `/freelancers?${
            this.$route.query.page ? `page=${this.$route.query.page}` : ""
          }${
            this.$route.query.search
              ? `&search=${this.$route.query.search}`
              : ""
          }${
            this.$route.query.branch
              ? `&branch=${this.$route.query.branch}`
              : ""
          }${
            this.$route.query.skill ? `&skill=${this.$route.query.skill}` : ""
          }`
        );
      } else if (this.$route.query.referer == "users") {
        this.$router.push(
          `/users?${
            this.$route.query.page ? `page=${this.$route.query.page}` : ""
          }${
            this.$route.query.search
              ? `&search=${this.$route.query.search}`
              : ""
          }${
            this.$route.query.parentSkill
              ? `&parentSkill=${this.$route.query.parentSkill}`
              : ""
          }${
            this.$route.query.subSkill
              ? `&subSkill=${this.$route.query.subSkill}`
              : ""
          }`
        );
      } else {
        this.$router.go(-1);
      }
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["getSingleUser", "loading"]),
    ...mapGetters({ currentUser: "currentUser" }),
    isAtOwnProfile() {
      return this.currentUser.id == this.$route.params.id;
    },
    canEditProfile() {
      let isWorker = false;
      if (this.getSingleUser && this.getSingleUser.role) {
        const roles = this.getSingleUser.role.map(role => role.title);
        isWorker = roles.includes("Worker");
      }
      return this.$can("can_edit_worker_profiles", "all") && isWorker;
    }
  },
  watch: {
    "$route.params.id"(value) {
      if (
        value &&
        (this.$can("edit_user_profile_info", "all") ||
          this.$route.params.id == this.currentUser.id)
      )
        this.getUserById(this.$route.params.id);
    }
  }
});
</script>
